/*!
 * JavaScript TheHost Locale
 * Copyright 2020
 */
;(function (factory) {
  window.Locale = factory();
}(function () {
  var api = {};
  var options = {
        cookieName: "priority_user_language",
        cookieParams: { expires: 400, path: '/', sameSite: 'strict' }
  };

  function init() {
    automaticLanguageChange();

    api.current = getLocale();
    api.real = getTrueLocale();
    api.setCookie = setLocalizationCookie;

    return api;
  }

  // Private: Get current locale by pathname.
  function getLocale() {
    var locale = "";
    if ((document.location.pathname.indexOf('/ua/') + 1) || document.location.pathname === "/ua" ) {
      locale = "/ua"
    } else if ((document.location.pathname.indexOf('/en/') + 1) || document.location.pathname === "/en") {
      locale = "/en"
    } else if (document.location.pathname.indexOf('/ru/') + 1) {
      locale = "/ru"
    }
    return locale;
  }

  // Private: Get current locale by pathname. Returns the locale of the form "ru", "ua" or "en".
  function getTrueLocale() {
    var locale = getLocale() || "/ru";
    return locale.slice(1)
  }

  /**
   * Private: Specifies the complete list of priority languages for the client browser.
   * Example: ["uk", "ru", "pl", "en", "ru-RU", "en-US"]
   * @return {array}
   */
  function getBrowserLocales(){
    var n = navigator;
    lang = n.languages ? n.languages :
      n.language ? n.language :
      n.userLanguage ? n.userLanguage :
      n.browserLanguage ? n.browserLanguage :
      [];
    return lang;
  }

  // Private: Performs a redirect to the desired site locale.
  function redirectToLocale(locale){
    document.location.replace(document.location.protocol + "//" + document.location.host + locale + document.location.pathname + document.location.hash);
  }

  // Private: Assistant for setting cookies with parameters.
  function setLocalizationCookie(val){ return Cookies.set(options.cookieName, val, options.cookieParams); }

  // Private: Set cookies corresponding to the language of the user's browser.
  // Returns the value of the cookie that was set.
  function setCookieAndRedirectByBrowserLocale(){
    var languageCode,
        browserLocaleCodes = getBrowserLocales(),
        languageCodesRu = gateway.global["languageCodesRu"];

    for (var i = 0; i < browserLocaleCodes.length; i++) {
      var priorityLanguage = browserLocaleCodes[i].substr(0, 2).toLowerCase();

      if (languageCodesRu.includes(priorityLanguage)) {
        languageCode = 'ru';
        break;
      } else if (priorityLanguage === "uk") {
        languageCode = 'ua';
        redirectToLocale("/ua");
        }
    }
    
    if (languageCode === undefined) {
      languageCode = 'en';
      redirectToLocale("/en");
    }

    setLocalizationCookie(languageCode);
    return languageCode;
  }

  // Private: Send an warning message.
  function pullMsg(message) { console.warn(message); }


  /**
   * Private: The function of automatic change of locale.
   * @return {boolean}
   */
  function automaticLanguageChange() {
    var locale;
    // If cookies are disabled or it is a bot, then we leave.
    if (!navigator.cookieEnabled || isBot(navigator.userAgent)) return false;

    locale = getTrueLocale();

    // Set cookies depending on locale.
    switch (locale) {
      case 'ru':
        var cookieLang = Cookies.get(options.cookieName);
        // Perform a redirect
        switch (cookieLang) {
          case 'ru':
            // We are already on the required locale and no redirects are needed.
            break;
          case 'ua':
            redirectToLocale("/ua");
            break;
          case 'en':
            redirectToLocale("/en");
            break;
          case undefined:
            // We are here because the cookie doesn't exist
            setCookieAndRedirectByBrowserLocale();
            break;
          default:
            pullMsg("Invalid cookie found: " + cookieLang);
            setCookieAndRedirectByBrowserLocale();
        }
        break;
      case 'ua':
        setLocalizationCookie("ua");
        break;
      case 'en':
        setLocalizationCookie("en");
        break;
      default:
        pullMsg("Not configured site localization: " + locale);
    }

    return true;
  }

  /**
   * Private: Check if string matches known crawler patterns.
   * @param  {string} userAgent
   * @return {boolean}
   */
  function isBot(userAgent) {
    var regex = /bot|crawler|spider|crawling|APIs-Google|Feedfetcher-Google|googleweblight|Mediapartners-Google|DuplexWeb-Google|Google Web Preview|Slurp|Python-urllib|python-requests|httpunit|Go-http-client|phpcrawl|BIGLOTRON|Gigablast|webmon|HTTrack|grub\.org|netresearchserver|fluffy|findlink|panscient|ips-agent|postrank|page2rss|findthatfile|europarchive\.org|summify|ec2linkfinder|facebookexternalhit|RetrevoPageAnalyzer|drupact|CC Metadata Scaper|g00g1e\.net|binlar|A6-Indexer|ADmantX|ltx71|BUbiNG|lipperhey|MetaURI|Scrapy|CapsuleChecker|collection@infegy\.com|Sysomos|Trove|deadlinkchecker|Embedly|iskanie|SkypeUriPreview|WhatsApp|Yahoo Link Preview|Xenu Link Sleuth|Pcore-HTTP|AppInsights|Digg Deeper|dcrawl|Snacktory|okhttp|MuckRack|NetcraftSurveyAgent|Jetty|Upflow|Thinklab|Traackr\.com|Nuzzel|omgili|PocketParser|Twurly|Mastodon|BrandVerity|EZID|Genieo|MeltwaterNews|Moreover|ScoutJet|adscanner|BTWebClient|Disqus|Fever|FlipboardProxy|vkShare|DareBoost|zgrab|Datafeedwatch|Zabbix|axios|Pulsepoint|WordupInfoSearch|WebDataStats|HttpUrlConnection|outbrain|FeedValidator|Blackboard|BazQux|Twingly|Rivva|AnyEvent|HeadlessChrome|SimpleScraper|fedoraplanet|Friendica|NextCloud|Datanyze|TrendsmapResolver|tweetedtimes|SimplePie|Siteimprove\.com|Dataprovider\.com|theoldreader\.com|PR-CY\.RU|2ip\.ru|um-LN|AppEngine-Google|google-xrawler|CloudFlare-AlwaysOnline|Google-Structured-Data-Testing-Tool|W3C-checklink|W3C-mobileOK|Google-PhysicalWeb|Chrome-Lighthouse|Google-Site-Verification|http_get|check_http|Amazon CloudFront|W3C_Validator|W3C_I18n-Checker|W3C_CSS_Validator|W3C_Unicorn|Nmap Scripting Engine|Google Favicon|Tiny Tiny RSS|BingPreview\/|Daum\/|NING\/|AHC\/|Buck\/|newspaper\/|Fetch\/|Miniflux\/|Validator\.nu|GroupHigh\/|MixnodeCache\/|Domains Project\/|^Apache-HttpClient|^curl|^PHP-Curl-Class/i;
    return regex.test(userAgent);
  }

  return init();
}));
